<template>
	<BracketMobile v-if="isMobile">
        <div class="has-space">
            <!-- <UefaAbout></UefaAbout> -->
        </div>
    </BracketMobile>
	<div v-else class="bracket-container">
		<img src="../../../../static/images/worldcup2022/afc_afcon_bracket_2023_bg.png">
		
		<div class="bracket-card__center__wrapper">
			<div class="bracket-card__center-title__wrapper">
				<div class="bracket-card__center-title">{{ $t('UEFA_EURO_2024') }}</div>
				<div class="bracket-card__center-title">{{ $t('LIVE_BRACKET') }}</div>
			</div>
            <div class="bracket-card__title-prize bracket-card__title-champion">{{ $t('CHAMPION') }}</div>
            <div class="bracket-card__title-prize" :class="[currentLocale === 'vn' ? 'bracket-card__title-final-longer' : 'bracket-card__title-final']">{{ $t('GRAND_FINAL') }}</div>
            <!-- <div class="bracket-card__title-prize bracket-card__title-third">{{ $t('3RD_PLACE') }}</div> -->
		</div>

		<div class="bracket-card" :class="`bracket-card__uefa-${entry.sequenceId}`"  v-for="(entry, index) in knockoutList" :key="index">
			<div class="bracket-card__status">{{ formatDateTime(entry) }}</div>	
			<div class="bracket-card__team-body">
				<div class="bracket-card__team-wrapper">
					<img class="bracket-card__team-icon" 
						:src="entry.homeId  ? `${imageUrlPrefix}/${entry.homeLogo}` : require('../../../../static/images/icons/icon_team.png') "
					>
                    <!-- icon_bracket_default.png -->
                    <div class="bracket-card__team-name-wrapper">
                        <div class="bracket-card__team-name" :title="formatTeamName(entry,'home')">{{ formatTeamName(entry,'home') }}</div>
                        <div v-if="verfiyIsPenaltyWin(entry, 'home')" class="bracket-card__team__penalty">P</div>
                    </div>
				</div>
				<div class="knockout-card__team__score">
					<div>{{ formatTeamScore(entry, 'home') }}</div>
					<div class="knockout-card__team-score-divider__wrapper"  v-if="entry.homePenaltyScore">
						<div class="knockout-card__team__score-divider"></div>
						<div class="knockout-card__team__score-only">{{ entry.homePenaltyScore }}</div>
					</div>
				</div>   
			</div>	
			<div class="bracket-card__team-body">
				<div class="bracket-card__team-wrapper">
					<img class="bracket-card__team-icon" 
						:src="entry.awayId  ? `${imageUrlPrefix}/${entry.awayLogo}` : require('../../../../static/images/icons/icon_team.png') "
					>		
                    <!-- icon_bracket_default.png -->
                    <div class="bracket-card__team-name-wrapper">
                        <div class="bracket-card__team-name" :title="formatTeamName(entry,'away')">{{ formatTeamName(entry,'away') }}</div>
                        <div v-if="verfiyIsPenaltyWin(entry, 'away')" class="bracket-card__team__penalty">P</div>
                    </div>
				</div>
				   <div class="knockout-card__team__score">
						<div>{{ formatTeamScore(entry, 'away') }}</div>
						<div class="knockout-card__team-score-divider__wrapper" v-if="entry.homePenaltyScore">
							<div class="knockout-card__team__score-only">{{ entry.awayPenaltyScore }}</div>
						</div>
					</div>
				<!-- <div class="bracket-card__team-score__wrapper">
					<div class="bracket-card__team-score">{{ formatTeamScore(entry, 'away') }}</div>
  					<div class="knockout-card__team-score-divider__wrapper" v-if="entry.homePenaltyScore">
						<div class="knockout-card__team__score-divider"></div>
						<div class="knockout-card__team__score-only">{{ entry.awayPenaltyScore }}</div>
					</div>
				</div> -->
			</div>
		</div>

		<div class="bracket-card__final">
			<div class="bracket-card__team-body">
				<div class="bracket-card__team-wrapper">
					<img v-if="finalBracket.hasLogo" class="bracket-card__team-icon" :src="`${imageUrlPrefix}/${finalBracket.teamLogo}`"> 
                    <img v-else class="bracket-card__team-icon" src="../../../../static/images/icons/icon_team.png">
                    <!-- icon_bracket_default.png -->
					<div class="bracket-card__team-name" :title="finalBracket.teamName">{{ finalBracket.teamName }}</div>
				</div>
			</div>
		</div>
	</div>
	<template>
		
	</template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import config from '@/js/config.js';
import moment from 'moment';
import BracketMobile from '@/views/footballEvents/uefa2024/BracketMobile.vue'
import UefaAbout from '@/views/footballEvents/uefa2024/components/UefaAbout.vue'

const defaultTeamName = 'TBD';
const defaultTeamScore = "-";
const defaultTeamLogo = "../../../../static/images/world_cup_default_team.png";

export default {
	components: {
		BracketMobile,
        UefaAbout
	},

	data() {
		return {
			imageUrlPrefix: config.s3ImgUrl,
            cnLocaleList: config.cnLocaleList,
			knockoutList: [],

			finalBracket: {
                teamLogo: "../../../../static/images/world_cup_default_team.png"
            }   
		}
	},
	computed: {
		...mapGetters([
			"isMobile",
			"currentLocale",
			"currentLanguageObj",
			"currentSportType",
		]),
	},
	mounted() {
        this.getKnockOutData();
    },
   	methods: {
        ...mapActions([
            "getUEFAKnockout",
        ]),
        //start get data section
        async getKnockOutData() {
            let params = {
                contestId: 4,
                language: this.currentLanguageObj.locale,
				lang: this.currentLanguageObj.apiParam,
				timeZone: this.$tools.getCurrentTimeZone(),
				displayAll: 1
            } 
            const result = await this.getUEFAKnockout(params);

			this.knockoutList = result.result.matchKnockouts;
			this.finalBracket = this.getFinalBracketData(this.knockoutList.filter(x => x.roundName === 'Final')[0]);
        },

		formatDateTime(entry) {
            if (entry.matchStatus) {
                return entry.matchStatus             
            } else {
                const momentTime = moment(entry.matchTime);
                
                if (this.cnLocaleList.includes(this.currentLocale)) {
                    return momentTime.format('Do MMM, HH:mm ');
                } else {
                    return momentTime.format('D MMM, HH:mm ');
                }
            }
        },

		formatTeamName(entry, status) {
            if (status === 'home') {
                if (entry.homeName) {
                    return entry.homeName
                } else {
                    return defaultTeamName
                }
            } else {
                 if (entry.awayName) {
                    return entry.awayName
                } else {
                    return defaultTeamName
                }
            }
        },

        formatTeamScore(entry, status) {
            if (status === 'home') {
                if (entry.homeScore) {
                    return entry.homeScore
                } else {
                    return defaultTeamScore
                }
            } else {
                 if (entry.awayScore) {
                    return entry.awayScore
                } else {
                    return defaultTeamScore
                }
            } 
        },

		
        verfiyIsPenaltyWin(entry, status) {
            if (status === 'home') {
                if (entry.homePenaltyScore > entry.awayPenaltyScore) {
                    return true
                } else  {
                    return false
                }
            } else {
                if (entry.awayPenaltyScore > entry.homePenaltyScore) {
                    return true
                } else {
                    return false
                }
            }
        },

		getFinalBracketData(entry) {
            let obj = {};

            if (entry.championId) {
                if (entry.championId === entry.homeId) {
                    obj.teamName = entry.homeName;
                    obj.teamLogo = entry.homeLogo;
                    obj.teamScore = entry.homeScore;
                    obj.hasLogo = true;
                } else if (entry.championId === entry.awayId) {
                    obj.teamName = entry.awayName;
                    obj.teamLogo = entry.awayLogo;
                    obj.teamScore = entry.awayScore;
                    obj.hasLogo = true;
                }
            } else {
                obj.teamName = defaultTeamName;
                obj.teamLogo = defaultTeamLogo;
                obj.teamScore = defaultTeamScore;
                obj.hasLogo = false;
            }
            return obj
        },
		

   }
}
</script>

<style>

.bracket-container{
	position: relative;
}
.bracket-card{
	border-radius: 8px;
	background: #35454D;
	width: 152px;
	height: 80px;
	position: absolute;
	padding: 8px;
    box-sizing: border-box;
}
.bracket-card__status{
	color: #CFD9DE;
	font-size: 11px;
	margin-bottom: 5px;
}
.bracket-card__uefa-37{
    top: 208px;
    left: 112px;
}
.bracket-card__uefa-40{
    top: 358px;
    left: 936px;
}
.bracket-card__uefa-38{
    top: 550px;
    left: 936px;
}
.bracket-card__uefa-41{
    top: 358px;
    left: 112px;
}
.bracket-card__uefa-45{
    top: 112px;
    left: 224px;
}
/* 16-6 */
.bracket-card__uefa-46{
    top: 454px;
    left: 224px;
}
.bracket-card__uefa-49{
    top: 335px;
    left: 336px;
}
.bracket-card__uefa-44{
    top: 208px;
    left: 936px;
}
.bracket-card__uefa-43{
    top: 16px;
    left: 936px;
}
.bracket-card__uefa-39{
    top: 16px;
    left: 112px;
}
.bracket-card__uefa-42{
    top: 550px;
    left: 112px;
}
.bracket-card__uefa-48{
    top: 454px;
    left: 824px;
}
.bracket-card__uefa-47{
    top: 112px;
    left: 824px;
}
.bracket-card__uefa-50{
    top: 335px;
    left: 713px;
}
.bracket-card__uefa-51{
    top: 335px;
    left: 525px;
}
.bracket-card__49{
	top: 16px;
    left: 112px;
}
.bracket-card__50{
	top: 208px;
    left: 112px;
}
.bracket-card__51{
	top: 16px;
    left: 936px;
}
.bracket-card__52{
    top: 208px;
    left: 936px;
}
.bracket-card__53{
	top: 358px;
    left: 112px;
}
.bracket-card__54{
	top: 550px;
    left: 112px;
}
.bracket-card__55{
	top: 358px;
    left: 936px;
}
.bracket-card__56{
	top: 550px;
    left: 936px;
}
.bracket-card__57{
	top: 112px;
    left: 224px;
}
.bracket-card__58{
	top: 454px;
  	left: 224px;
}
.bracket-card__59{
	top: 112px;
    left: 824px;
}
.bracket-card__60{
	top: 454px;
  	left: 824px;
}
.bracket-card__61{
    top: 335px;
    left: 336px;
}
.bracket-card__62{
	top: 335px;
  	left: 713px;
}
.bracket-card__63{
	top: 496px;
  	left: 525px;
}
.bracket-card__64{
	top: 335px;
    left: 525px;
}
.bracket-card__team-body{
	display: flex;
	justify-content: space-between;
	font-size: 11px;
	color: #CFD9DE;
	margin-bottom: 9px;
}
.bracket-card__team-icon{
	margin-right: 3px;
    width: 15px;
}
.bracket-card__team-wrapper{
	display: flex;
	align-items: center;
}
.bracket-card__center-wrapper{
	position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.bracket-card__center-stripe{
    position: absolute;
    left: 369px;
    top: 0px;
}
.bracket-card__center-flower{
	position: absolute;
    object-fit: contain;
    top: 197px;
    left: 619px;
	z-index: -1;
}
.bracket-card__center-title{
	font-size: 36px;
    font-style: italic;
    font-weight: 700;
    -webkit-text-stroke: 2px white;
    text-shadow: 2px 2px 6px #ffffff40;
    color: transparent;
	text-align: center;
}
.bracket-card__center__wrapper{
	position: absolute;
    top: 0;
	width: 100%;
}
.bracket-card__center-title__wrapper{
    top: 3rem;
    position: absolute;
    left: 25rem;
    width: 403px;
    text-align: center;
}
.bracket-card__title-prize{
    position: absolute;
    width: 152px;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    text-align: center;
}
.bracket-card__title-champion{
    left: 32.5rem;
    top: 12rem;
}
.bracket-card__title-third{
    left: 32.5rem;
    top: 29rem;
}
.bracket-card__title-final{
    left: 32.5rem;
    top: 19rem;
}
.bracket-card__title-final-longer {
    left: 32.5rem;
    top: 17rem;
}
.bracket-card__final{
	width: 152px;
    height: 34px;
    background: #35454D;
    position: absolute;
    top: 221px;
    left: 521px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
}
.bracket-card__team-name-wrapper {
    display: flex;
}
.bracket-card__team-name{
	/* display: flex; */
	line-height: 18px;
    max-width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}
.bracket-card__team__penalty{
    background-color: #5CD66C;
    border-radius: 0.25rem;
    font-size: 9px;
    height: 16px;
    min-width: 14px;
    text-align: center;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}
.knockout-card__team-score-divider__wrapper{
    display: flex;
    margin-left: 4px;
}
.knockout-card__team__score-divider{
    position: absolute;
    width: 1px;
    height: 44px;
    background-color: #4B616C;
}
.knockout-card__team-score-divider__wrapper{
    display: flex;
    margin-left: 4px;
}
.knockout-card__team__score-divider{
    position: absolute;
    width: 1px;
    height: 44px;
    background-color: #4B616C;
}
.knockout-card__team__score-only{
    width: 12px;
    text-align: right;
}	
.knockout-card__team__score {
    position: absolute;
    right: 9px;
    width: auto;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
}
</style>